

/* GATO GIF */
.div1{
    grid-area: div1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0B1D51;
    border-radius: 15px;
}

.div1 > div {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.div1 video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px; 
}
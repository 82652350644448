/* FORMULARIO */
.formulario {
    grid-area: formulario;
    background-color: #FFFDFD;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
}

.titulo-form {
    text-align: center;
    font-size: 1.7rem;
    font-family: 'Merriweather', sans-serif;
    font-weight: 400;
    color: #0B1D51;
    margin: 10px 10px;
}

.subtitulo-form {
    text-align: center;
    font-size: 1.25rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    color: #0B1D51;
    margin: 10px 10px;
}

.formulario form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: center;
    align-items: flex-start;
    width: 80%;
    height: 80%;
}

.formulario form :last-child {
    align-self: center;
    font-size: 1.25rem;
}

.formulario label {
    color: #0B1D51;
    font-size: 1rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    margin: 10px 0;
    text-align: left;
}

.formulario input {
    color: #0B1D51;
    font-size: 1rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    text-align: left;
    background-color: #FFFDFD;
    border: none;
    border-bottom: 1px solid #0B1D51;
    width: 100%;
}

.formulario select {
    font-size: 1rem; 
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    width: 100%;
    color: #0B1D51;
    background-color: #FFFDFD;
    border: none;
    border-bottom: 1px solid #0B1D51;
    padding: 10px;
    box-sizing: border-box;
}

.formulario select option {
    color: #0B1D51;
    font-size: 17px!important; 
    padding: 10px; 
    box-sizing: border-box;
    height: 30px; 
    
}

.formulario textarea {
    color: #0B1D51;
    font-size: 1.2rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    margin: 10px 0;
    text-align: left;
    background-color: #FFFDFD;
    border: none;
    border-bottom: 1px solid #0B1D51;
    width: 100%;
    height: 20%;
    overflow: hidden;
}

.formulario form input[type="submit"] {
    background-color: #0B1D51;
    color: #C6EBBE;
    border: none;
    text-align: center;
    border-radius: 15px;
    font-size: 1.5rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    width: 50%;
    padding: 10px;
    margin: 20px 10px;
}

.formulario form input[type="submit"]:hover {
    background-color: #C6EBBE;
    color: #0B1D51;
}

.exito{
    background-color: #0B1D51;
    padding: 10px;
    color: #C6EBBE;
    font-size: 1.5rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    border-radius: 15px;
}
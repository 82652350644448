/* WHATSAPP */
.whatsapp {
    grid-area: whatsapp;
    background-color: #7CA5B8;
    border-radius: 15px;
    display: flex;
    justify-content: center; 
    align-items: center; 
    width: 100%; 
    height: 100%; 
}

.whatsapp a {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    width: 100%; 
    height: 100%; 
}

.whatsapp img {
    width: 40%; 
    height: auto;
    max-width: 100%; 
    max-height: 100%;
    border-radius: 15px; 
}
.whatsapp img:hover {
    width: 50%; 
    height: auto;
    max-width: 100%; 
    max-height: 100%;
    border-radius: 15px; 
    transition: 0.3s;
}

/* MIRA MIS PROYECTOS */
.div3{
    grid-area: div3;
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 15px;
    position: relative;
}

.proyectos{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.div3 .proyectos h2{
    z-index: 2;
    text-align: center;
    grid-area: 1 / 1;
    font-size: clamp(2rem, 5vw, 3rem);
    font-family: 'Merriweather', sans-serif;
    font-weight: 900;
    color: #ffffff;
}


.div3 div {
    grid-area: 1 / 1 / 2 / 2; 
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 15px;
}

.div3 div video {
    grid-area: 1 / 1 / 2 / 2; 
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px; 
    z-index: 1;
}

.div3 h2:hover{
    color: #38369A;
    background-color: #A9DBB8;
    padding: 10px;
    border-radius: 15px;
    transition: 0.3s;
    cursor: pointer;
}
.proyectos{
    text-decoration: none;
}
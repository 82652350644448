.sobre-mi-detalle {
    background-color: #0B1D51;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    padding: 10px;
}

.sobre-mi-detalle a {
    text-decoration: none;
    font-size: 1.25rem;
    color: #ffffff;
}

.wraper-detalle-volver-sobremi {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 6vh;
    margin-top: 10px;
}

.wraper-detalle-volver-sobremi h4 {
    margin: 0;
    color: #A9DBB8;
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
}

.volver {
    height: 30px;
    width: 30px;
}

.titulo-sobremi-detalle {
    text-align: center;
    font-size: 1.5rem;
    margin: 5px 10px;
    color: #ffffff;
}


.texto-sobremi-detalle {
    text-align: center;
    font-size: 1rem;
    max-width: 35ch;
    margin: 30px 10px;
    color: #ffffff;
}

.contactame-detalle {
    background-color: #A9DBB8;
    border-radius: 15px;
    color: #0B1D51;
    font-size: 1rem;
    font-weight: bold;
    padding: 12px;
    margin: 0 0 30px 0;
    cursor: pointer;
    border-color: #ffffff;
}

.contactame-detalle:hover {
    background-color: #7CA5B8;
    color: #ffffff;
    transition: 0.3s;
}
.nav{
    grid-area: nav;
    background-color: rgb(56, 54, 155,0.8);
    border-radius: 15px;
    position: fixed;
    width: 90vw;
    z-index: 3;
}

.nav ul{
    display: flex;
    justify-content: space-around;
    align-content: center;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.nav ul li{
    text-align: center;
    font-size: 1.5rem;
    font-family: 'Cinzel', serif;
    font-weight: 700;
    color: #ffffff;
    margin: 10px;
}

.nav ul li:hover{
    cursor: pointer;
    color: #0B1D51;
    transition: 0.3s;
    font-weight: bold;
    background-color: #C6EBBE;
    padding: 0 20px;
    border-radius: 15px;
}

@media screen and (max-width: 425px) {
    .nav{
        display: none;
    }
}  
.section1-mobile { grid-area: 1 / 1 / 2 / 3; }
.titulo-sobremi-mobile {
    text-align: center;
    font-size: 1.5rem; 
    font-family: 'Merriweather', sans-serif;
    font-weight: 900;
    margin: 10px;
    color: #0B1D51;
    border-radius: 15px;
    padding: 10px;
}

.section2-mobile { 
    grid-area: 2 / 1 / 3 / 2;
    cursor: pointer;
    width:100%;
}

.sobre-mi-mobile{
    text-decoration: none;
    text-align: center;
    font-size:15px ;
    font-family: 'Merriweather', sans-serif;
    font-weight: 900;
    margin: 10px;
    color: #ffffff;
    background-color: #0B1D51;
    border-radius: 15px;
}

.section3-mobile { 
    grid-area: 3 / 1 / 4 / 2;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    cursor: pointer;
    overflow: hidden;
}
.section3-mobile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    background-color: #0B1D51;
}


.section4-mobile {
    grid-area: 3 / 2 / 4 / 3; 
    background-color: #ffffff;
    border-radius: 15px;
    cursor: pointer;
    height: 100%;
}

.section4-mobile a{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    text-decoration: none;
}

.section4-mobile div {
    grid-area: 1 / 1 / 2 / 2; 
    width: 100%;
    height: 100%;
    overflow: hidden; 
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section4-mobile div video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px; 
}

.section4-mobile .titulo-proyectos-mobile {
    grid-area: 1 / 1 / 2 / 2;
    z-index: 2;
    text-align: center;
    font-size: 1.5rem;
    font-family: 'Merriweather', sans-serif;
    font-weight: 900;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section5-mobile { 
    grid-area: 4 / 1 / 5 / 3; 
    background-color: #0B1D51;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    gap: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
}
/* IMAGENES STACK */
.section5-mobile img{
    width: 9%;
    max-height: 85%;
    border-radius: 15px;
}

.section6-mobile {
    grid-area: 5 / 1 / 6 / 2;
    height: 20vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.escribinos-mobile {
    border-radius: 15px;
    background-color: #7CA5B8;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.escribinos-mobile video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 15px; 
}


.section7-mobile { 
    grid-area: 5 / 2 / 6 / 3; 
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items:center;
    height: 100%;
    width: 100%;
    gap: 10px;
    background-color: #7CA5B8;
    border-radius: 15px;

}

.correo-mobile{
    width: 50%;
    align-self:center;
    margin: 5px;
}
.correo-mobile img{
    width: 100%;
    height: auto;
    cursor: pointer;
}

.whatsapp-mobile{
    width: 50%; 
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
}

.whatsapp-mobile a img {
    width: 100%; 
    height: auto;
    cursor: pointer;
}


.section8-mobile { 
    grid-area: 6 / 1 / 7 / 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    border-radius: 15px;
    cursor: pointer;
    
}

.section8-mobile a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section8-mobile h2{
    text-align: center;
    font-size: 1rem;
    font-family: 'Merriweather', sans-serif;
    font-weight: 700; 
    color: #0B1D51;
    border-radius: 15px;
}
.section8-mobile h3{
    text-align: center;
    font-size: 0.8rem;
    font-family: 'Merriweather', sans-serif;
    font-weight: 700; 
    margin: 10px;
    color: #0B1D51;
    border-radius: 15px;
    padding: 10px;
}
.section8-mobile img{
    width: 30%; 
    height: auto;
    margin: 0 10px;
}

.section9-mobile { 
    grid-area: 6 / 2 / 7 / 3;
    background-color: #0B1D51;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section9-mobile > div {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section9-mobile video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px; 
}

